import { Box } from "@mui/material";
import CustomHeader from "../home/CustomHeader";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { zIndex } from "@mui/material/styles";
import { Oval } from "react-loader-spinner";

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "absolute" , zIndex:9999, top:'50%',left:'50%'}}>
     
      <Oval
        visible={true}
        height="50"
        radius="50"
        width="50"
        color="#fff"
        secondaryColor="#cccc"
        ariaLabel="revolving-dot-loading"
        wrapperClass=""
      />
      {/* <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={50}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={50}
        thickness={5}
        {...props}
      /> */}
    </Box>
  );
}

const position = [51.505, -0.09];
const CircleWithMovingPoint = () => {
  return (
    <>
      <FacebookCircularProgress />
      <Box
        style={{
          zIndex: 1000,
        }}
      >
        <MapContainer
          center={position}
          zoom={3}
          scrollWheelZoom={false}
          style={{ minHeight: "100vh", minWidth: "100vw", filter: "blur(8px)" }}
        >
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          
        </MapContainer>
      </Box>
    </>
  );
};

export default CircleWithMovingPoint;
