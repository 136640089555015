import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function RequestAccountModal({ open, setOpen }) {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [disableButton, setDisableButton] = React.useState(false);

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
    });
    setOpen(false);
  };

  const showToast = (message, type = "success") => {
    toast[type](message, {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleSubmitButton = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    const { name, email, phoneNumber } = formData;

    if (!name.trim() || !email.trim() || !phoneNumber.trim()) {
      showToast("Please fill all fields", "error");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Invalid email format", "error");
      return;
    }

    try {
      const response = await axios.post("https://escan-systems.com/api/requestAccount",{
        name: name,
        email: email,
        phoneNumber: phoneNumber
      });
      if (response.status === 200) {
        showToast("Your request for account is sent successfully", "success");
        handleClose();
      } else {
        showToast("Error occurred. Please try again later", "error");
      }
    } catch (error) {
      showToast("Error occurred. Please try again later", "error");
    }
    setDisableButton(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            backgroundColor: "#212930",
            padding: 3,
            borderRadius: "16px",
          },
          onSubmit: handleSubmitButton,
        }}
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            color={"white"}
          >
            <Typography variant="h5" component={"div"}>
              Request Account
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ marginTop: "20px" }}>
          <DialogContentText
            display={"flex"}
            justifyContent={"center"}
            mb={2}
            color={"white"}
          >
            Enter your details to request account.
          </DialogContentText>
          <TextField
            placeholder="John Doe"
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            placeholder="john@domain.com"
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            placeholder="03XXXXXXXXX"
            margin="dense"
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" style={{backgroundColor:"rgb(242,126,48)"}} disabled={disableButton}>
            Request Account
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </React.Fragment>
  );
}
