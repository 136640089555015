import React, { useState } from 'react';
import ESS_LOGO from "../../public/images/ESS-06.png"

import {
  Box,
  Typography,
  Button
} from '@mui/material';
import SignUpPage from './SingupCard'; // Import your SignUpPage component
import { useWindowSize } from "@uidotdev/usehooks";
import LoginCard from './LoginCard';

const CustomCard = () => {
  const size = useWindowSize();
  const [activeButton, setActiveButton] = useState('signin');
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        background: "#181F26",
        width:'100%'
      }}
    >
      <img src={ESS_LOGO} width={"250px"}  mt={18}/>
      <Typography
        mt={3}
        mr={3}
        ml={3}
        variant="h5"
        sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'white', textAlign:'center'}}
      >
        Manage your fields easier - Monitor online
      </Typography>
      <Typography
        mt={1}
        mr={3}
        ml={3}
        variant="h5"
        sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'white', textAlign:'center'}}
      >
        Detect in real-time
      </Typography>
      <Typography
        mt={1}
        mr={3}
        ml={3}
        variant="h5"
        sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'white', textAlign:'center'}}
      >
        Act smart
      </Typography>
      <Box
        style={{
          borderRadius: '5%',
          width: size.width > 768 ? '60%' : '80%',
          background: '#212930',
          marginTop: '20px',
          marginBottom : '50px'
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {/* <Button
            style={{
              width: '50%', 
              background: activeButton === 'signup' ? '' : 'black',
            }}
            onClick={() => handleButtonClick('signup')}
          >
            <Typography
              mt={2}
              mb={2}
              
              style={{
                color: activeButton === 'signup' ? 'white' : '#9FB4C7',
                textTransform: 'capitalize' 
              }}
            >
              {activeButton === 'signup'
                ? 'Signup'
                : 'signup'.charAt(0).toUpperCase() + 'signup'.slice(1)}
            </Typography>
          </Button> */}
          <Button
            style={{
              width: '50%',
              background: activeButton === 'signin' ? '' : 'black',
            }}
            onClick={() => handleButtonClick('signin')}
          >
            <Typography
              mt={2}
              mb={2}
              style={{
                color: activeButton === 'signin' ? 'white' : '#9FB4C7',
                textTransform: 'capitalize' 
              }}
            >
              <b>Login to Monitor your Field</b>
            </Typography>
          </Button>
        </Box>
        <Box>
          {activeButton === "signin" && <LoginCard />}
          {/* {activeButton === 'signup' && <SignUpPage />}
           */}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomCard;
