const cropsArray = [
    "Agave",
    "Agave Cupreata",
    "Agave salmiana",
    "Alfalfa",
    "Almonds",
    "Anise",
    "Apple",
    "Areca nut",
    "Artichoke",
    "Asparagus",
    "Avocado",
    "Bananas",
    "Basil",
    "Beans",
    "Becva festulolium",
    "Belladonna",
    "Bitter melon",
    "Black kale",
    "Blackberry",
    "Blessed thistle",
    "Blue agave",
    "Blueberry",
    "Bluegrass",
    "Broccoli",
    "Broccoli rabe",
    "Buckwheat",
    "Calendula",
    "Canola",
    "Caraway",
    "Carrot",
    "Cashew",
    "Cassava",
    "Castor crop",
    "Casuarina",
    "Cauliflower",
    "Celebration Bermuda grass",
    "Celery",
    "Centipede grass",
    "Cereal",
    "Chamomile",
    "Chard",
    "Cherry",
    "Chia",
    "Chickpea",
    "Chicory",
    "Chilli",
    "Citrus",
    "Clover",
    "Cocoa",
    "Coconut",
    "Coffee",
    "Coriander",
    "Corn (Maize)",
    "Cotton",
    "Cowpea",
    "Cranberry",
    "Cranberry bean",
    "Cucumber",
    "Cumin",
    "Dandelion",
    "Date palm",
    "Devil's claw",
    "Dragon fruit",
    "Durian",
    "Echinacea",
    "Eggplant",
    "Elder",
    "Emerald Zoysia grass",
    "Endive",
    "Eucalyptus",
    "Fababean",
    "Fennel Bitter",
    "Fennel Sweet",
    "Fescue grass",
    "Feverfew",
    "Figs",
    "Flax",
    "Forage Grass",
    "Fruit",
    "Garlic",
    "Ginger",
    "Gotu Kola",
    "Grapes",
    "Grass cover",
    "Green beans",
    "Groundnut",
    "Guava",
    "Gum arabic",
    "Hazelnut",
    "Hemp",
    "Hibiscus",
    "Hyssop",
    "Iceberg lettuce",
    "Indian cress",
    "Jamur Zoysia Grass",
    "Kale",
    "Kiwi",
    "Kola nut",
    "L1F Zoysia Grass",
    "Lady's mantle",
    "Lavender",
    "Lazer Zoysia Grass",
    "Lemon Balm",
    "Lemon Grass",
    "Lemon Thyme",
    "Lemon Verbena",
    "Lemons",
    "Lentils",
    "Lettuce",
    "Lucerne",
    "Lychee",
    "Mallow",
    "Mandarin",
    "Mango",
    "Melon",
    "Meyer Zoysia grass",
    "Milk thistle",
    "Millet",
    "Mint",
    "Mixed cereals",
    "Moringa",
    "Moss",
    "Mullein",
    "Mungbean",
    "Mustard",
    "Narrowleaf Plantain",
    "Nasturtium",
    "Nolina",
    "Nuts",
    "Oats",
    "Oil palm",
    "Oil palm Elaeis guineensis",
    "Oil palm Elaeis Oleifera",
    "Oil palm Hybrid OxG",
    "Oilseed crops",
    "Okra",
    "Olive",
    "Olive tree",
    "Onions",
    "Oranges",
    "Other",
    "Papaya",
    "Paprika",
    "Parsley",
    "Passion flower",
    "Passion fruit",
    "Pasture",
    "Peach",
    "Peanuts",
    "Pear",
    "Peas",
    "Pecan",
    "Pepper",
    "Peppermint",
    "Pigeonpea",
    "Pineapple",
    "Pistachio",
    "Plantain",
    "Plum",
    "Pomegranate",
    "Poppy seed",
    "Potatoes",
    "Primo Zoysia Grass",
    "Prism Zoysia Grass",
    "Pulses",
    "Pumpkin",
    "Radicchio",
    "Rambutan",
    "Rapeseed",
    "Raspberry",
    "Red clover",
    "Reed",
    "Rest area",
    "Rice",
    "Romaine lettuce",
    "Romanesco broccoli",
    "Rose",
    "Rosehip",
    "Rosemary",
    "Roseroot",
    "Rubber",
    "Rudbeckia",
    "Rye",
    "Safflower",
    "Sage",
    "Sainfoin",
    "Savoy cabbage",
    "Senna",
    "Sesame",
    "Shallot",
    "Silage corn (Maize)",
    "Silage sorghum",
    "Snap Peas",
    "Solidago",
    "Sorghum",
    "Sorghum sudanense",
    "Southern Blue Bluegrass",
    "Soybeans",
    "Spearmint",
    "Spice",
    "Spinach",
    "Spring barley",
    "Spring cereals",
    "Spring rapeseed",
    "Spring triticale",
    "Sprout",
    "St. John's wort",
    "Stadium Zoysia Grass",
    "Stevia",
    "Stinging nettle",
    "Strawberry",
    "Sugar beet",
    "Sugarcane",
    "Summer fallow",
    "Sunbelt Bluegrass",
    "Sunflower",
    "Sweet potato",
    "Table grapes",
    "Tangerine",
    "Tea",
    "Teak",
    "Thyme",
    "TifBlair Centipede Grass",
    "TifGrand Bermuda Grass",
    "TifTuf Bermuda grass",
    "TiWay Bermuda grass",
    "Tobacco",
    "Tomatoes",
    "Trinity Zoysia Grass",
    "Triticosecale",
    "Tuber crops",
    "Turmeric",
    "Turnip",
    "Valerian",
    "Vegetables",
    "Vervain",
    "Vineyard",
    "Walnuts",
    "Watermelon",
    "Wheat",
    "Willow",
    "Winter barley",
    "Winter cereals",
    "Winter rapeseed",
    "Winter rye",
    "Winter sorghum",
    "Winter triticale",
    "Winter wheat",
    "Yams",
    "Yarrow",
    "Yellow woodsorrel",
    "Zenith Zoysia grass",
    "Zeon Zoysia grass",
    "Zucchini"
  ];
  
  export default cropsArray;
  