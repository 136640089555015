// CustomCarousel.js
import React, { useState,useEffect } from "react";
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CustomCarousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);

  return (
    <Box sx={{ position: "relative", height: "100%",background: "linear-gradient(to bottom, transparent, black)", }}>
      <Paper
        sx={{
          textAlign: "center",
          padding: 2,
          backgroundColor: "transparent",
          position: "absolute",
          bottom: 50,
          width: "100%",
          
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.6rem', color: 'white' }}>
          {slides[currentSlide].title}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.2rem', color: 'white' }}>
          {slides[currentSlide].content}
        </Typography>
      </Paper>
      <Stack
        direction="row"
        spacing={4}
        sx={{
          position: "absolute",
          bottom: 30,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {slides.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleDotClick(index)}
            sx={{
              cursor:'pointer',
              width: 12,
              height: 12,
              borderRadius: "50%",
              backgroundColor: index === currentSlide ? "white" : "grey",
            }}
          />
        ))}
      </Stack>

      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          left: 15,
          top: "50%",
          transform: "translateY(-50%)",
          borderRadius: "50%",
          backgroundColor: "rgba(65,62,61, 0.7)",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        <ArrowBackIcon style={{color:'white'}}/>
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: 15,
          top: "50%",
          transform: "translateY(-50%)",
          borderRadius: "50%",
          backgroundColor: "rgba(65,62,61, 0.7)",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        <ArrowForwardIcon style={{color:'white'}}/>
      </IconButton>
    </Box>
  );
};

export default CustomCarousel;
