import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import { Divider, Grid, MenuItem, Select, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { CalendarIcon } from "@mui/x-date-pickers";
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  ArrowDownward,
  ArrowForward,
  ExpandMore,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUpSharp,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as TP,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { color } from "@mui/system";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import moment from "moment";
import axios from "axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { MSAVI } from "../utils/constants";
export default function DateCard({
  dateStart,
  dateEnd,
  center,
  farm,
  dataArray,
  onCwrClick,
  selectedIndex,
  onViewIDClick,
  dateRangeUpdate,
  onIndexChange,
  onZoningChange,
  setShowUpdateFieldModal,
  setLegendComponentPosition,
  layer
}) {
  const theme = useTheme();
  const [showRange, setShowRange] = React.useState(false);
  console.log("center", center);
  const today = new Date();
  const containerRef = React.useRef(null);
  const [showLeftButton, setShowLeftButton] = React.useState(false);
  const [showRightButton, setShowRightButton] = React.useState(true);
  const [tData, setTData] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [myDataArray, setMyDataArray] = React.useState(dataArray);
  const [getMyIndex, setMyIndex] = React.useState(selectedIndex); 

  const [weatherGraphData, setWeatherGraphData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [collapsed, setCollapsed] = React.useState(false); 

  const [selectedDate, setSelectedDate] = React.useState(null);
  const getStats = async (layer,index) => {
    let data = JSON.stringify({
      type: "mt_stats",
      params: {
        bm_type: index,
        date_start: dateStart,
        date_end: dateEnd,
        geometry: layer.features[0].geometry,
        reference: "ref_20200924-00-00",
        sensors: ["sentinel2"],
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://escan-systems.com/api/getStats",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
     
        setTimeout(() => {
          getTaskResult(response.data.task_id)
            .then((data) => {
              //
            })
            .catch((error) => {
              // Handle errors here
              console.error("Error in useEffect:", error);
            });
        }, 10000);
      })
      .catch((error) => {});
  };

  const getTaskResult = async (taskID) => {
    const dateArray = [];
    let config = {
      method: "get", // Change method to "get"
      maxBodyLength: Infinity,
      url: `https://escan-systems.com/api/getTaskResult/${taskID}`, // Update URL with taskID parameter
      headers: {},
    };

    await axios
      .request(config)
      .then((response) => {
        if ("status" in response.data) {
          setTimeout(() => {
            getTaskResult(taskID);
          }, 10000);
        } else {
          response.data.result.forEach(async (view) => {
            dateArray.push({
              date: moment(view.date).format("MMM DD, YYYY"),
              view: view.view_id,
              cloud: view.cloud,
              sceneID: view.scene_id,
              average: view.average,
              max: view.max,
              median: view.median,
              min: view.min,
              p10: view.p10,
              variance: view.variance,
              p90: view.p90,
              q1: view.q1,
              q3: view.q3,
              std: view.std,
            });
          });
          dateArray.sort((a, b) => {
            // Parse the date strings back to date objects
            const dateA = moment(a.date, "MMM DD, YYYY").toDate();
            const dateB = moment(b.date, "MMM DD, YYYY").toDate();

            // Compare the date objects
            return dateA - dateB;
          });
          console.log("dateArray",dateArray)
          setMyDataArray(dateArray);

          // dateArray.forEach((item) => {
          //   const parsedDate = moment(item.date, "MMM DD, YYYY");
          //   const month = parsedDate.format("MMM");
          //   const day = parsedDate.format("DD");
          //   graphArray.push({
          //     sortDate: item.date,
          //     date: day + " " + month,
          //     [selectedIndex]: parseFloat(item.average).toFixed(2),
          //     tmax: "",
          //     tmin: "",
          //     humidity: "",
          //   });
          // });
          // //(parseFloat(farmArea) * 2.471).toFixed(2)
          // const sortedArray = graphArray.slice().sort((a, b) => {
          //   // Convert the date strings to Date objects for comparison
          //   const dateA = new Date(a.sortDate);
          //   const dateB = new Date(b.sortDate);
      
          //   // Compare the dates
          //   return dateA - dateB;
          // });
      
          // // Log or use the sorted array
      
          // setGraphData(sortedArray);

        }

        //
      })
      .catch((error) => {
        //  console.log("WHAT IS THIS",dateArray)
      });
  };
  const handleDateClick = (date, view) => {


    setSelectedDate(date);
    getViewID(view);
  };

  const farmArea = farm;
  const areaInAcres = (parseFloat(farmArea) * 2.471)
    .toFixed(2)
    .toString()
    .concat(" Acres");
  const [selectionRange, setSelectionRange] = React.useState([
    {
      startDate: "2023-12-01",
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleIconClick = () => {
    setShowRange(!showRange);
  };

  const onWeatherChange = async (event) => {
    /* 
     Rain
    "parameters": "wind_speed_2m:ms,relative_humidity_2m:p,precip_24h:mm,t_max_2m_24h:C,t_min_2m_24h:C,evapotranspiration_24h:mm/50.447235,30.522595/json"
    
    */
    const newIndex = event.target.value;
    console.log(newIndex);
    if (newIndex == "Temperature") {
      const max = [];
      const min = [];
      /* 
      
       MaxTemperature: obj1.MaxTemperature || undefined,
              MinTemperature: correspondingObj2.MinTemperature || undefined,
              WindSpeed: correspondingObj3.windSpeed || undefined,
              Precipitation: correspondingObj4.Precipitation || undefined,
              Humidity: correspondingObj5.humidity || undefined,
              ETo: correspondingObj6.ETo || undefined,
      */
      console.log(weatherGraphData);
      // setWeatherGraphData(mergedArray)
      // setFilterData(mergedArray)
      weatherGraphData.forEach((item) => {
        max.push({
          sortDate: item.sortDate,
          date: item.date,
          MaxTemperature: item.MaxTemperature,
          MinTemperature: item.MinTemperature,
        });
      });

      setFilterData(max);
    } else if (newIndex == "Humidity") {
      const hum = [];
      weatherGraphData.forEach((item) => {
        hum.push({
          sortDate: item.sortDate,
          date: item.date,
          Humidity: item.Humidity,
        });
      });

      setFilterData(hum);
    } else if (newIndex == "Wind") {
      const wind = [];
      weatherGraphData.forEach((item) => {
        wind.push({
          sortDate: item.sortDate,
          date: item.date,
          WindSpeed: item.WindSpeed,
        });
      });

      setFilterData(wind);
    } else if (newIndex == "Rain") {
      const Precipitation = [];
      weatherGraphData.forEach((item) => {
        Precipitation.push({
          sortDate: item.sortDate,
          date: item.date,
          Precipitation: item.Precipitation,
        });
      });

      setFilterData(Precipitation);
    } else if (newIndex == "Eto") {
      const ETo = [];
      weatherGraphData.forEach((item) => {
        ETo.push({
          sortDate: item.sortDate,
          date: item.date,
          ETo: item.ETo,
        });
      });

      setFilterData(ETo);
    } else if ("All") {
      setFilterData(weatherGraphData);
    }
  };
  const _onIndexChange = async (event) => {
    const newIndex = event.target.value;
    if(newIndex == "MSAVI"){
      getStats (layer,MSAVI)
    }else{
      getStats (layer,newIndex)
    }
    setMyIndex(newIndex)
    onIndexChange(newIndex);
  };

  const handleCwrClick = async () => {
    onCwrClick();
  };

  const getViewID = (view) => {
    console.log("DASD",view)
    onViewIDClick(view);

    
  };
  const handleDateRangeChange = (ranges) => {
     
    const newSelectionRange = ranges.selection;

  };
  React.useEffect(() => {
    console.log("dateRange", selectionRange);
    dateRangeUpdate(selectionRange);
  }, [selectionRange]);

  React.useEffect(() => {
    getTempratureData();
    console.log("selectedIndex", selectedIndex);
    const handleScroll = () => {
      const container = containerRef.current;

      if (container) {
        // Check if the left button should be shown
        setShowLeftButton(container.scrollLeft > 0);

        // Check if the right button should be shown
        setShowRightButton(
          container.scrollLeft < container.scrollWidth - container.clientWidth
        );
      }
    };

    // Add scroll event listener to update button visibility
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    const graphArray = [];
    myDataArray.forEach((item) => {
      const parsedDate = moment(item.date, "MMM DD, YYYY");
      const month = parsedDate.format("MMM");
      const day = parsedDate.format("DD");
      graphArray.push({
        sortDate: item.date,
        date: day + " " + month,
        [selectedIndex]: parseFloat(item.average).toFixed(2),
        tmax: "",
        tmin: "",
        humidity: "",
      });
    });
    //(parseFloat(farmArea) * 2.471).toFixed(2)
    const sortedArray = graphArray.slice().sort((a, b) => {
      // Convert the date strings to Date objects for comparison
      const dateA = new Date(a.sortDate);
      const dateB = new Date(b.sortDate);

      // Compare the dates
      return dateA - dateB;
    });

    // Log or use the sorted array

    setGraphData(sortedArray);
    return () => {
      // Remove the scroll event listener when the component is unmounted
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ myDataArray ]);

  const getTempratureData = async () => {
    const requestData = {
      center: center,
      dateStart: dateStart,
      dateEnd: dateEnd,
    };

    try {
      const response = await axios.post(
        "https://escan-systems.com/api/dateCardData",
        requestData
      );
      const data = response.data;
      const maxArray = [];
      const minArray = [];
      const windSpeed = [];
      const precipitationArray = [];
      const humidityArray = [];
      const etoArray = [];

      data.data.forEach((item) => {
        if (item.parameter == "t_max_2m_24h:C") {
          item.coordinates[0].dates.forEach((dateWise) => {
            const parsedDate = moment(dateWise.date.split("T")[0]);
            const month = parsedDate.format("MMM");
            const day = parsedDate.format("DD");
            const parsed = day + " " + month;
            maxArray.push({
              sortDate: dateWise.date.split("T")[0],
              date: day + " " + month,
              MaxTemperature: parseFloat(dateWise.value).toFixed(2),
            });
          });
        }

        if (item.parameter == "t_min_2m_24h:C") {
          item.coordinates[0].dates.forEach((dateWise) => {
            const parsedDate = moment(dateWise.date.split("T")[0]);
            console.log("PARSED", parsedDate);
            const month = parsedDate.format("MMM");
            const day = parsedDate.format("DD");
            const parsed = day + " " + month;
            console.log("MINX DATA", parsed, dateWise.date.split("T")[0]);
            minArray.push({
              sortDate: dateWise.date.split("T")[0],
              date: day + " " + month,
              MinTemperature: parseFloat(dateWise.value).toFixed(2),
            });
          });
        }
        if (item.parameter == "relative_humidity_2m:p") {
          item.coordinates[0].dates.forEach((dateWise) => {
            const parsedDate = moment(dateWise.date.split("T")[0]);
            const month = parsedDate.format("MMM");
            const day = parsedDate.format("DD");
            const parsed = day + " " + month;
            console.log("MINX DATA", parsed, dateWise.date.split("T")[0]);
            humidityArray.push({
              sortDate: dateWise.date.split("T")[0],
              date: day + " " + month,
              humidity: parseFloat(dateWise.value).toFixed(2),
            });
          });
        }

        if (item.parameter == "wind_speed_2m:ms") {
          item.coordinates[0].dates.forEach((dateWise) => {
            const parsedDate = moment(dateWise.date.split("T")[0]);
            const month = parsedDate.format("MMM");
            const day = parsedDate.format("DD");
            const parsed = day + " " + month;
            console.log("MINX DATA", parsed, dateWise.date.split("T")[0]);
            windSpeed.push({
              sortDate: dateWise.date.split("T")[0],
              date: day + " " + month,
              windSpeed: parseFloat(dateWise.value).toFixed(2),
            });
          });
        }

        if (item.parameter == "precip_24h:mm") {
          item.coordinates[0].dates.forEach((dateWise) => {
            const parsedDate = moment(dateWise.date.split("T")[0]);
            const month = parsedDate.format("MMM");
            const day = parsedDate.format("DD");
            const parsed = day + " " + month;
            console.log("MINX DATA", parsed, dateWise.date.split("T")[0]);
            precipitationArray.push({
              sortDate: dateWise.date.split("T")[0],
              date: day + " " + month,
              Precipitation: parseFloat(dateWise.value).toFixed(2),
            });
          });
        }

        // if (item.parameter == "evapotranspiration_24h:mm") {
        //   item.coordinates[0].dates.forEach(dateWise => {
        //     const parsedDate = moment(dateWise.date.split("T")[0]);
        //     const month = parsedDate.format('MMM');
        //     const day = parsedDate.format('DD');
        //     const parsed = day + " " + month
        //     console.log("MINX DATA", parsed, dateWise.date.split("T")[0])
        //     etoArray.push({
        //       sortDate: dateWise.date.split("T")[0],
        //       date: day + " " + month,
        //       ETo: parseFloat(dateWise.value).toFixed(2)

        //     })
        //   })
        // }
      });
      const mergedArray = maxArray.map((obj1) => {
        const correspondingObj2 = minArray.find(
          (obj2) => obj2.sortDate === obj1.sortDate && obj2.date === obj1.date
        );
        const correspondingObj3 = windSpeed.find(
          (obj3) => obj3.sortDate === obj1.sortDate && obj3.date === obj1.date
        );
        const correspondingObj4 = precipitationArray.find(
          (obj4) => obj4.sortDate === obj1.sortDate && obj4.date === obj1.date
        );
        const correspondingObj5 = humidityArray.find(
          (obj5) => obj5.sortDate === obj1.sortDate && obj5.date === obj1.date
        );
        //  const correspondingObj6 = etoArray.find(obj5 => obj5.sortDate === obj1.sortDate && obj5.date === obj1.date);

        if (
          correspondingObj2 &&
          correspondingObj3 &&
          correspondingObj4 &&
          correspondingObj5
        ) {
          // Merge the properties from all objects
          return {
            sortDate: obj1.sortDate,
            date: obj1.date,
            MaxTemperature: obj1.MaxTemperature || undefined,
            MinTemperature: correspondingObj2.MinTemperature || undefined,
            WindSpeed: correspondingObj3.windSpeed || undefined,
            Precipitation: correspondingObj4.Precipitation || undefined,
            Humidity: correspondingObj5.humidity || undefined,
            //  ETo: correspondingObj6.ETo || undefined,
          };
        }

        // If any corresponding object is not found, return obj1
        return obj1;
      });

      setWeatherGraphData(mergedArray);
      setFilterData(mergedArray);
    } catch (error) {
      console.error(error);
    }
    // Make a POST request to your custom API endpoint
  };

  const CustomLegend = () => {
    return (
      <ul style={{ listStyle: "none", padding: 0 }}>
        {graphData.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,
              marginRight: "20px",
              display: "inline-block",
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {entry.date} + {":"} + {parseFloat(entry.average).toFixed(2)}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    const load = payload[0];

    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            display: "flex",
            background: "rgb(1,2,3)",
            borderRadius: "8px",
            padding: "8px 8px 1px 8px",
          }}
        >
          <p
            className="label-heading"
            style={{ color: "#00E676", paddingLeft: "10px" }}
          >
            {`${selectedIndex} (${label}):`}{" "}
          </p>
          <p className="label-value" style={{ paddingRight: "10px" }}>
            {" "}
            {`  ${payload[0].value} `}{" "}
          </p>
        </div>
      );
    }

    return null;
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#28282b" : "#28282b",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <>
      {dataArray.length > 0 ? (
        <>
          <Card
            sx={{
              display: "flex",
              backgroundColor: "#28282B",
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip placement="top" title={"Select Date"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  pl: 1,
                  pb: 1,
                  padding: 1,
                }}
              >
                <IconButton aria-label="previous" onClick={handleIconClick}>
                  <CalendarIcon color="black" style={{ color: "white",marginTop: "8px", }} />
                </IconButton>

                <Divider
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "black",
                    width: "2px",
                    height: "90%",
                    margin: "auto 0",
                  }}
                />
              </Box>

              {showRange && (
               <div>
               <DateRangePicker
                 onChange={(item) => setSelectionRange([item.selection])}
                 months={2}
                 direction="horizontal"
                 showSelectionPreview={true}
                 moveRangeOnFirstSelection={false}
                 ranges={selectionRange}
                 maxDate={today}
                 isClearable={true}
               />
               <Button
                onClick={handleIconClick}
                variant="contained"
                
                sx={{
                  position: 'absolute',
                  bottom: '430px', // Adjust this value as needed
                  left: '850px',
                  zIndex:'9999',
                  width: '36px !important',
                  height:'64px',
                  borderRadius: '100%'
                }}
              >
                <CloseIcon/>
              </Button>
             </div>
              )}

              {/* <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} /> */}
            </Tooltip>

            {showLeftButton && (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  if (containerRef.current) {
                    // containerRef.current.scrollLeft -= 100;
                    containerRef.current.scrollBy({
                      left: -100,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                <IconButton aria-label="previous">
                  <KeyboardArrowLeft color="black" style={{ color: "white" }} />
                </IconButton>
              </Box>
            )}
            <Card
              ref={containerRef}
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#28282B",
              }}
            >
              {dataArray.map((dataItem, index) => (
                <>
                  <Tooltip
                    placement="top"
                    title={`Cloud coverage ${dataItem.cloud} %`}
                  >
                    <Box
                      onClick={() =>
                        handleDateClick(dataItem.date, dataItem.view)
                      }
                      sx={{
                        width: "300px",
                        borderRadius: "5px",
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        border:
                          selectedDate === dataItem.date
                            ? "1px solid rgb(255, 255, 255)"
                            : "", // Change color when selected
                        cursor: "pointer", 
                      }}
                    >
                      <Box sx={{}}>
                        <IconButton aria-label="previous">
                          <SatelliteAltIcon
                            color="black"
                            style={{
                              color:'white',
                            }}
                          />
                        </IconButton>
                      </Box>
                      <Box sx={{}}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          id="dateText"
                          sx={{
                            color:
                              selectedDate === dataItem.date
                                ? "white"
                                : "white",
                          }}
                        >
                          {dataItem.date}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                </>
              ))}
            </Card>

            {showRightButton && (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  if (containerRef.current) {
                    // containerRef.current.scrollLeft += 100;
                    containerRef.current.scrollBy({
                      left: 100,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                <IconButton aria-label="previous">
                  <KeyboardArrowRight
                    color="black"
                    style={{ color: "white" }}
                  />
                </IconButton>
              </Box>
            )}

            <Box
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                const newCollapsed = !collapsed;
                setCollapsed(newCollapsed);
                setLegendComponentPosition(newCollapsed ? "10%" : "34%");
              }}
            >
              <IconButton aria-label="previous">
                {!collapsed ? (
                  <ExpandMore color="black" style={{ color: "white" }} />
                ) : (
                  <KeyboardArrowUpSharp
                    color="black"
                    style={{ color: "white" }}
                  />
                )}
              </IconButton>
            </Box>
          </Card>
        </>
      ) : (
        <></>
      )}

      <Box
        sx={{
          width: 1,
          height: "400px",
          direction: "row",
          p: 1,
          backgroundColor: "#28282B",
          borderRadius: 0.5,
          fontSize: "0.875rem",
          fontWeight: "700",
          textAlign: "center",
          display: collapsed ? "none" : "flex", // Set a maximum height for slide-down effect
          transition: "display 0.3s ease", // Adjust the duration and timing function as needed
        }}
      >
        <Box
          sx={{
            width: "15%",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#28282B",
            }}
          >
            <CardContent
              sx
              style={{
                alignItems: "center",
                alignContent: "center",
                paddingBottom: 10,
                paddingLeft: 0,
              }}
            >
              <Tooltip placement="top" title={areaInAcres}>
                <Typography
                  variant="subtitle2"
                  color="#c5c5c5"
                  component="div"
                  style={{ textAlign: "start", fontWeight: "bold" }}
                >
                  {`Source: Sentinel-2`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#c5c5c5"
                  component="div"
                  style={{ textAlign: "start", fontWeight: "bold" }}
                >
                  {`Area of Farm: ${farmArea} Ha`}{" "}
                  <AddCircleOutlineIcon
                    onClick={() => setShowUpdateFieldModal(true)}
                    style={{
                      cursor: "pointer",
                      color: "rgb(59,201,226)",
                      fontSize: "20px",
                    }}
                  />
                </Typography>
              </Tooltip>
            </CardContent>
          </Box>
          {/* <Card variant="outlined"
            sx={{ padding:4,color:'white'}} > */}
          <Typography align="left" color={"white"}>
            <strong>Indices</strong>
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            InputLabel="Indices"
            defaultValue="NDVI"
            defaultChecked={true}
            onChange={_onIndexChange}
            label="Indices"
            sx={{
              height: "45px",
              width: "100%",
              marginBottom: "5px",
              backgroundColor: "#28282B",
              color: "white",
              color: "white",
              "& fieldset": {
                borderColor: "lightgrey",
              },
              "&:hover fieldset": {
                borderColor: "lightgrey",
              },
              "&.Mui-focused fieldset": {
                borderColor: "lightgrey",
              },
              "& .MuiSelect-select": {
                color: "white",
              },
            }}
            style={{ backgroundColor: "#28282B" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                left: "16px",
              },
              transformOrigin: {
                vertical: "top",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  backgroundColor: "#28282B",
                  color: "white",
                },
              },
            }}
          >
            <MenuItem value={"NDVI"}>NDVI</MenuItem>
            <MenuItem value={"MSAVI"}>MSAVI</MenuItem>
            <MenuItem value={"NDRE"}>NDRE</MenuItem>
            <MenuItem value={"NDMI"}>NDMI</MenuItem>
            <MenuItem value={"RECI"}>RECI</MenuItem>
          </Select>

          <Typography align="left" color={"white"} mt={2} mb={2}>
            <strong>Weather</strong>
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            InputLabel="Weather Data"
            defaultValue="All"
            onChange={onWeatherChange}
            label="Weather Data"
            sx={{
              height: "45px",
              width: "100%",
              marginBottom: "5px",
              backgroundColor: "#28282B",
              color: "white",
              color: "white",
              "& fieldset": {
                borderColor: "lightgrey",
              },
              "&:hover fieldset": {
                borderColor: "lightgrey",
              },
              "&.Mui-focused fieldset": {
                borderColor: "lightgrey",
              },
              "& .MuiSelect-select": {
                color: "white",
              },
            }}
            style={{ backgroundColor: "#28282B" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                left: "16px",
              },
              transformOrigin: {
                vertical: "top",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  backgroundColor: "#28282B",
                  color: "white",
                },
              },
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Temperature"}>Temperature</MenuItem>
            <MenuItem value={"Rain"}>Rain Fall</MenuItem>
            <MenuItem value={"Humidity"}>Humidity</MenuItem>
            <MenuItem value={"Wind"}>Wind Speed</MenuItem>
            {/* <MenuItem value={'Eto'}>Eto</MenuItem>  */}
          </Select>

          <Typography align="left" color={"white"} mt={2} mb={2}>
            <strong>Zoning</strong>
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            InputLabel="Weather Data"
            defaultValue="All"
            onChange={onZoningChange}
            label="Weather Data"
            sx={{
              height: "45px",
              width: "100%",
              marginBottom: "5px",
              backgroundColor: "#28282B",
              color: "white",
              color: "white",
              "& fieldset": {
                borderColor: "lightgrey",
              },
              "&:hover fieldset": {
                borderColor: "lightgrey",
              },
              "&.Mui-focused fieldset": {
                borderColor: "lightgrey",
              },
              "& .MuiSelect-select": {
                color: "white",
              },
            }}
            style={{ backgroundColor: "#28282B" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                left: "16px",
              },
              transformOrigin: {
                vertical: "top",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  backgroundColor: "#28282B",
                  color: "white",
                },
              },
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"productivity"}>Productivity Map</MenuItem>
            <MenuItem value={"vegetation"}>Vegetation Map</MenuItem>
            {/* <MenuItem value={'Eto'}>Eto</MenuItem>  */}
          </Select>
          {/* </Card> */}

          {/* <Divider component="div"  sx={{
            marginTop:2,
            background:'white',
            height:'1px'
          }}/> */}

          {/* <Select
            labelId="demo-simple-select-label"
            InputLabel="Zoning"
            label="Zoning"
            defaultValue={"Zoning"}
            onChange={_onZoningChange}
            sx={{
              width: '100%',
              marginTop: 2,
              backgroundColor: '#28282B',
              color: 'white',
              color: 'white',
              '& fieldset': {
                borderColor: 'lightgrey',
              },
              '&:hover fieldset': {
                borderColor: 'lightgrey',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'lightgrey',
              },
              '& .MuiSelect-select': {
                color: 'white',
              },
            }}


            style={{ backgroundColor: '#28282B' }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                left: '16px'
              },
              transformOrigin: {
                vertical: 'top',
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  backgroundColor: '#28282B',
                  color: 'white',
                },
              },
            }}
          >
            <MenuItem value={'vegetation'}>Vegetation Map</MenuItem>
            <MenuItem value={'productivity'}>Productivity Map</MenuItem>

          </Select> */}

          <Box
            sx={{
              height: "55px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              padding: "8px",
              color: "#d9d9d9",
              cursor: "pointer",
              marginTop: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleCwrClick}
          >
            {"Calculate Crop Water Requirement"}
          </Box>
        </Box>

        {/* <ResponsiveContainer width="100%" height="100%"> */}

        {filterData.length > 0 && (
          <LineChart
            width={750}
            height={300}
            data={filterData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            style={{ color: "white" }}
          >
            <TP />

            <Legend />
            <XAxis dataKey="date" />
            <YAxis />

            <Line
              type="monotone"
              dataKey="MaxTemperature"
              stroke="#FF0000"
              dot={false}
              activeDot={{ r: 5 }}
            />
            <Line
              type="monotone"
              dataKey="MinTemperature"
              stroke="#1170ed"
              dot={false}
              activeDot={{ r: 5 }}
            />
            {/* <Line type="monotone" dataKey="ETo" stroke="#74ccf4" dot={false} activeDot={{ r: 5 }} />  */}
            <Line
              type="monotone"
              dataKey="WindSpeed"
              stroke="#00ff00"
              dot={false}
              activeDot={{ r: 5 }}
            />
            <Line
              type="monotone"
              dataKey="Precipitation"
              stroke="#0f5e9c"
              dot={false}
              activeDot={{ r: 5 }}
            />
            <Line
              type="monotone"
              dataKey="Humidity"
              stroke="#1ca3ec"
              dot={false}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        )}

        <LineChart
          width={750}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={{ color: "white" }}
        >
          <TP label="INPUT" content={<CustomTooltip />} />

          <Legend />
          <XAxis dataKey="date" />
          <YAxis />

          <Line
            type="monotone"
            dataKey={getMyIndex}
            stroke="#00e676"
            dot={false}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </Box>
    </>
  );
}
