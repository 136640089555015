import { Box } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
export default function OuterCard({imageURL}){
    const windowSize = useWindowSize();
    return(
        <Box
        style={{
          borderRadius: "50px",
          backgroundImage:
            `url("${imageURL}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: windowSize.width < 1950 ? "70vh" : "45vh",
        }}
      />
    )
}