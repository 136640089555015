

import * as L from "leaflet";
import { useMap } from "react-leaflet"; 
import { useEffect, useState } from "react"; 

export default function LocationFound( coordinates ) {
    console.log("COOR",coordinates)
    const centerCoordinates = coordinates;
    const map = useMap();
    const [position, setPosition] = useState(null)
    useEffect(() => {
        console.log("coordinates.coordinates.length ",coordinates.coordinates.toString().length )
       if(coordinates.coordinates.toString().length  > 0 )
         map.flyTo(coordinates.coordinates, 20)
    }, [coordinates])
    return null;
    // const map = useMapEvents({
    //   click() {
    //     map.locate()
    //   },
    //   exactLocation(e) {
    //     setPosition(e.latlng)
    //     map.flyTo(e.latlng, map.getZoom())
    //   },
    // })

    // return position === null ? null : (
    //   <Marker position={position}>
    //     <Popup>You are here</Popup>
    //   </Marker>
    // )
  }

   