import { useRoutes, Navigate } from "react-router-dom";
import { useAuth } from "./components/hooks/useAuth";
import MapView from "./components/advisory";
import Homepage from "./components/home/Homepage";
import Registration from "./components/registration/LoginPage";
import PrivacyPolicy from "./components/privacypolicy";

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/registration" />;
  }
  return children;
};

const AppRouter = () => {
  const { user } = useAuth();

  const routes = useRoutes([
    { path: `${process.env.PUBLIC_URL}`, element: <Homepage /> },
    {
      path: `${process.env.PUBLIC_URL}/registration`,
      element: user ? <Navigate to="/monitor" /> : <Registration />,
    },
    {
      path: `/monitor`,
      element: (
        <ProtectedRoute>
          <MapView />
        </ProtectedRoute>
      ),
    },
    { path: "*", element: <Navigate to={`${process.env.PUBLIC_URL}`} /> },
    { path: '/privacy-policy', element: <PrivacyPolicy  /> },
  ]);

  return routes;
};

export default AppRouter;
