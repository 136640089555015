import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const pixelCards = [
  {
    svgURL: "https://eos.com/wp-content/uploads/2023/08/icon-home-1.svg",
    title: "On-the-fly",
    points: [
      "On-the-fly",
      "tile-based map rendering",
      "indices generation",
      "detecting changes in one area for different periods of time (deforestation/forest operations, construction, vegetation growth)",
      "cloud/shadow masking",
      "atmospheric and radiometric correction application",
      "NDVI analysis",
    ],
  },
  {
    svgURL: "https://eos.com/wp-content/uploads/2023/08/icon-home-2.svg",
    title: "A stand-alone service",
    points: [
      "identify field contours",
      "calculate seeding/fertilizer rates",
      "make soil maps/soil moisture maps",
      "monitor weather risks and precipitation",
      "create crop profiles",
      "predict yields",
    ],
  },
  {
    svgURL: "https://eos.com/wp-content/uploads/2023/08/icon-home-3.svg",
    title: "On request",
    points: [
      "defining objects by training the neural network model using high-resolution data",
      "landsat-8 & Sentinel-2 data harmonization",
      "developing and applying custom algorithms in imagery processing",
      "detecting forest cover change and carbon emissions",
      "classification of tree types",
      "extracted volume calculation",
      "illegal mining tracking",
      "mineral mapping & classification",
      "monitoring oil & gas exploration",
    ],
  },
];

const PixelCard = () => {
  return pixelCards.map((pixelCard) => (
    <Card
      style={{
        margin: "10px",
        height: "600px",
        width: "380px",
        backgroundColor: "#212a33",
      }}
    >
      <CardContent style={{ overflowY: "auto", height: "100%" }}>
        <Box
          style={{
            backgroundColor: "#11151b",
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            border:'1px solid white',
            overflow: "hidden",
            margin: "auto",
          }}
        >
          <img
            src={pixelCard.svgURL}
            alt="SVG Icon"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Typography
          mt={2}
          variant="h5"
          color="white"
          align="center"
          gutterBottom
        >
          {pixelCard.title}
        </Typography>
        {pixelCard.points.map((point) => (
          <Box
            display="flex"
            justifyContent={"start"}
            alignItems={"start"}
            mt={1}
          >
            <CheckIcon color="success" style={{ fontSize: "25px" }} />
            <Typography variant="body1" align="left" color={"white"} ml={1}>
              {point}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  ));
};

export default PixelCard;
