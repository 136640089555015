import { Box, Grid } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import CustomContact from "./CustomContact";

export default function HomeComponent4() {
  const windowSize = useWindowSize();
  return (
    <div id="contact">
    <Grid container component="main">
      {/* <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            backgroundColor: "#212a33",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: windowSize.width < 992 ? "column-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: windowSize.width < 992 ? "85%" : "40%" }}>
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>Watch Video About EOSDA</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color={"white"}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                EOS Data Analytics is one of the leading global geospatial
                analytics providers on the market, partnering with governmental,
                commercial, and scientific organizations. EOSDA creates
                satellite-driven solutions for agriculture, forestry, and
                mining, while also actively exploring 22 more industries. The
                Company sees its mission as helping businesses around the world
                become more sustainable via innovative precision observation and
                data analytics tools.
              </strong>
            </Typography>
          </Box>
          <Box
            style={{
              width: windowSize.width < 992 ? "90%" : "40%",
              position: "relative",
            }}
          >
            <img
              src="https://i.ytimg.com/vi/fzj4yQtOOCU/maxresdefault.jpg"
              width="100%"
              alt="earth"
            />
            <PlayCircleFilledWhiteIcon
              style={{
                fontSize: "50px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#d1d2d4",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "10px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
         <CustomContact/>
        </Box>
      </Grid>

    </Grid>
    </div>
  );
}
