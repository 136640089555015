import { configureStore, createSlice } from '@reduxjs/toolkit';

// Define your initial state for different pieces of state
const initialDateState = {
  parsedDate: [],
};


const initialCloudState = {
  parsedData: [],
};


const initialAreaState = {
  area:'',
};


const initialFarmIdState = {
  farmId:'',
};


const initialImageState = {
  imageURL:'',
};

const initialBoundsState = {
  bound:'',
};





// Define slices for each piece of state using createSlice
const dateSlice = createSlice({
  name: 'date',
  initialState: initialDateState,
  reducers: {
    updateDateState: (state, action) => {
      state.parsedDate = action.payload;
    },
  },
});

const cloudSlice = createSlice({
  name: 'cloud',
  initialState: initialCloudState,
  reducers: {
    updateCloudCoverage: (state, action) => {
      state.parsedData = action.payload;
    },
  },
});


 
const areaSlice = createSlice({
  name: 'area',
  initialState: initialAreaState,
  reducers: {
    updateAreaState: (state, action) => {
      state.area = action.payload;
    },
  },
});

const imageURLSlice = createSlice({
  name: 'imageURL',
  initialState: initialImageState,
  reducers: {
    updateImageURLState: (state, action) => {
      state.imageURL = action.payload;
    },
  },
});
 

const boundsSlice = createSlice({
  name: 'bound',
  initialState: initialBoundsState,
  reducers: {
    updateBoundsState: (state, action) => {
      state.bound = action.payload;
    },
  },
});


const farmIdSlice = createSlice({
  name: 'id',
  initialState: initialFarmIdState,
  reducers: {
    updateFarmIdState: (state, action) => {
      state.farmId = action.payload;
    },
  },
});
 


const rootReducer = {
  date: dateSlice.reducer, 
  cloud: cloudSlice.reducer,
  area: areaSlice.reducer,
  id: farmIdSlice.reducer,
  bound: boundsSlice.reducer,
  imageURL:imageURLSlice.reducer
};

export const { updateDateState } = dateSlice.actions; 
export const { updateCloudCoverage } = cloudSlice.actions; 

export const { updateAreaState } = areaSlice.actions; 
export const { updateFarmIdState } = farmIdSlice.actions; 
export const { updateBoundsState } = boundsSlice.actions; 
export const { updateImageURLState } = imageURLSlice.actions; 

const store = configureStore({
  reducer: rootReducer,
});

export default store;
