import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";

export default function HomeComponent2() {
  const windowSize = useWindowSize();
  return (
    <div id="about">
          <Grid container component="main">
      {/* <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            backgroundColor: "#212a33",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: windowSize.width < 992 ? "column-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: windowSize.width < 992 ? "85%" : "40%" }}>
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>Watch Video About EOSDA</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color={"white"}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                EOS Data Analytics is one of the leading global geospatial
                analytics providers on the market, partnering with governmental,
                commercial, and scientific organizations. EOSDA creates
                satellite-driven solutions for agriculture, forestry, and
                mining, while also actively exploring 22 more industries. The
                Company sees its mission as helping businesses around the world
                become more sustainable via innovative precision observation and
                data analytics tools.
              </strong>
            </Typography>
          </Box>
          <Box
            style={{
              width: windowSize.width < 992 ? "90%" : "40%",
              position: "relative",
            }}
          >
            <img
              src="https://i.ytimg.com/vi/fzj4yQtOOCU/maxresdefault.jpg"
              width="100%"
              alt="earth"
            />
            <PlayCircleFilledWhiteIcon
              style={{
                fontSize: "50px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#d1d2d4",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "10px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            color={"white"}
            mb={1}
            style={{ fontWeight: "bold", color: "#f27e30" }}
          >
            <strong>What we do</strong>
          </Typography>
          <Typography
            variant="body1"
            component="div"
            textAlign={"justify"}
            mb={5}
            style={{color:"rgb(193, 207, 219)" }}
          >

              By harnessing the power of remote sensing, we are committed to
              fostering sustainable development, enhancing resource management,
              and contributing to a deeper understanding of our planet's
              intricate dynamics. Join us on our mission to unlock the full
              potential of Earth observation and drive positive change for a
              better, more sustainable future. At ESS, we are at the forefront
              of integrating remote sensing, the Internet of Things (IoT), and
              data analytics to pioneer sustainability solutions. Our remote
              sensing capabilities allow us to capture detailed and dynamic data
              about the Earth's surface, offering vital insights into climate
              change, land utilization, and resource management. By weaving this
              data with our advanced IoT infrastructure, we establish a network
              of sensors that deliver real-time environmental intelligence,
              monitoring everything from energy consumption to natural habitat
              conditions. Our expertise in data analytics transforms this
              information into actionable insights, enabling us to predict
              trends, identify efficiencies, and recommend sustainable
              practices. We empower businesses, municipalities, and
              organizations to not only track their environmental impact but
              also to make informed decisions that contribute to a sustainable
              future. With ESS, you're not just adopting cutting-edge
              technology; you're investing in a sustainable planet.

          </Typography>
          <Typography
            variant="h5"
            component="div"
            color={"white"}
            style={{ fontWeight: "bold", color: "#f27e30" }}
          >
            <strong>Our Services</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={service_1}
              titleText="Agriculture Monitoring and Precision Agriculture"
              bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. By leveraging the power of remote sensing technology, we enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhancing crop yields, reducing waste, and improving the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
            />
          ) : (
            <FinalCard
              imageURL={service_1}
              titleText="Agriculture Monitoring and Precision Agriculture"
              bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. By leveraging the power of remote sensing technology, we enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhancing crop yields, reducing waste, and improving the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
              leftPosition={true}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={service_2}
              titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
              bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our sophisticated GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
            />
          ) : (
            <FinalCard
              imageURL={service_2}
              titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
              bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our sophisticated GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
              rightPosition={true}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}  style={{ backgroundColor: "#181f26" }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "50px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={service_3}
              titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
              bodyText="Our advanced forest monitoring services are designed to empower stakeholders in the management and conservation of forests, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling forest owners and managers to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
            />
          ) : (
            <FinalCard
              imageURL={service_3}
              titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
              bodyText="Our advanced forest monitoring services are designed to empower stakeholders in the management and conservation of forests, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling forest owners and managers to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
              leftPosition={true}
            />
          )}
        </Box>
      </Grid>
      {/* <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          pt={5}
          pb={1}
          pr={2}
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "10px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Box
            style={{
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#f27e30" }}
            >
              <strong>INDUSTRIES</strong>
            </Typography>
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                Discover effective solutions for the top industries plus more
                upon request
              </strong>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
        <Box
          style={{
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          <CustomCarousel />
        </Box>
      </Grid> */}
    </Grid>
    </div>
  );
}
